<template>
  <layout :loading="loading">
    <template #header>
      <h1 class="title-1">Taken Books – {{ takedBooks.length }}</h1>
    </template>
    <taked-books
      page-view
      :books="takedBooks"
      @onLikeBook="handleLikeBook"
      @onReturnBook="handleReturnBook"
    />
  </layout>
</template>

<script>
import { mapActions } from 'vuex';
import TakedBooks from '@/components/library/taked-books.vue';

export default {
  name: 'ReturnBooks',
  components: {
    TakedBooks
  },
  data: () => ({
    loading: false,
    isMounted: false,
  }),
  async mounted() {
    this.loading = true;
    await this.getEmployeeTakedBooks();
    this.isMounted = true;
    this.loading = false;
    if (!this.takedBooks.length) {
      this.$router.push({ name: 'library' });
    }
  },
  methods: {
    ...mapActions('library', [
      'likeBook',
      'returnBook',
      'getEmployeeTakedBooks',
    ]),
    async handleLikeBook({ bookId, isLike }) {
      this.loading = true;
      await this.likeBook({
        employee_uid: this.user.employee.uid,
        library_item_id: bookId,
        is_like: isLike
      });
      await this.getEmployeeTakedBooks();
      this.loading = false;
    },
    async handleReturnBook(bookId) {
      this.loading = true;
      await this.returnBook(bookId);
      await this.getEmployeeTakedBooks();
      this.loading = false;
    },
  },
  computed: {
    user() {
      return this.$store.state.user.user;
    },
    takedBooks() {
      return this.$store.state.library.takedBooks;
    },
  },
  watch: {
    takedBooks(list) {
      if (this.isMounted && !list.length) {
        setTimeout(() => {
          this.$router.push({ name: 'library' });
        }, 1100);
      }
    }
  }
}
</script>
